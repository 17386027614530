<template>
    <el-card>


        <el-form-item>

            <label style="margin-left: 20px">年份：</label>
            <el-select v-model="year" class="m-2" placeholder="Select" @change="getSchoolDetail">
                <el-option
                        v-for="item in years"
                        :key="item"
                        :label="item"
                        :value="item"
                />
            </el-select>

            <el-input v-model="majorName" @keyup.enter="getSchoolDetail"  placeholder="请输入专业名称" style="margin-left: 40px;width: 200px"/>


            <el-button @click="getSchoolDetail">

                <el-icon>
                    <Search />
                </el-icon>搜索
            </el-button>

        </el-form-item>




        <el-table :data="majorScoreList"  style="width: 100%"
                  :header-cell-style="{ background: '#2775b6', color: 'white' }"
                  max-height="500px">
            <el-table-column fixed prop="majorId" label="专业代码" width="100"/>
            <el-table-column fixed prop="majorName" label="专业名称" width="180"/>
            <el-table-column prop="schoolType" label="类别" />


                <el-table-column prop="studentNumber" label="计划招生"  width="100"/>
                <el-table-column prop="maxScore" label="最高分" />
                <el-table-column prop="avgScore" label="平均分" />
                <el-table-column prop="minScore" label="最低分" />
                <el-table-column prop="avgRank" label="平均分位次"  width="100"/>
                <el-table-column prop="minRank" label="最低分位次"  width="100"/>
                <el-table-column prop="scoreLineDifference" label="线差" />





        </el-table>
    </el-card>
</template>

<script setup>
    import { useRoute } from "vue-router";
    import { useStore } from "vuex";
    import {ref,computed, onMounted} from "vue";
    import request from "@/utils/request";
    import {ElMessage} from "element-plus";



            const thisYear = new Date().getFullYear();

            const lastYear = thisYear - 1;
            const beforeYear = thisYear - 2;

            const years = ref([]);

            const school = ref({});
            const route = useRoute();
            let schoolId = ref(route.query.schoolId)
            const majorScoreList = ref([]);

            const majorName = ref('');

            const store = useStore();

            const year = ref(years[0]);


            const getYears = async ()=>{

               await request.get("/api/school/getYears",{
                    params:{
                        schoolId: schoolId.value,
                    }
                }).then((res)=>{
                    if (res.code == 20000){

                        years.value = res.data.years;
                        year.value = res.data.years[0]
                    }else {
                        ElMessage.error(res.message)
                    }
                }).finally(()=>{

                    getSchoolDetail();
                })

            }

            getYears();

            const getSchoolDetail = async () => {

              await  request
                    .get("/api/school/getThreeBySchoolId",{
                        params:{
                            schoolId: schoolId.value,
                            year: year.value,
                            majorName: majorName.value
                        }
                    })
                    .then((res) => {

                            if (res.code == 20000) {
                                school.value = res.data.school;
                                majorScoreList.value = res.data.majorScoreList;
                                //years.value = res.data.years
                                /*majorScoreList.value = res.data.schoolDetail.majorScoreList;
                                beforeThreeYearsMinScores.value = res.data.schoolDetail.beforeThreeYearsMinScores;*/


                            } else {
                                //years.value = res.data.years
                                ElMessage.error({
                                    message: "获取失败:" + res.message,
                                });
                            }

                    })

            }





</script>

<style scoped>

</style>