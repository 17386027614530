<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',
 /* created(){
    document.title = '巽风数据';
  },*/

}
</script>

<style>
html {
  overflow-y: scroll;
}
</style>
