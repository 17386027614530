<template>
    <el-container class="container" style="height: 100%;width: 100%">
        <el-header style="height: 10%">
            <SchoolMenu></SchoolMenu>
        </el-header>
        <el-main>
            <router-view></router-view>
        </el-main>
    </el-container>
</template>


<script>
    import SchoolMenu from "../../components/SchoolMenu"
    export default {
        components: {
            SchoolMenu
        },
        name: "SchoolMain"

    }


</script>

<style scoped>

</style>