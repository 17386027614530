<template>
    <el-card style="">
    <span>
        {{school.message}}
    </span>
    </el-card>



</template>

<script>
    import { useRoute } from "vue-router";
    import { useStore } from "vuex";
    import {ref,computed, onMounted} from "vue";
    import request from "@/utils/request";
    import {ElMessage} from "element-plus";
    import * as echarts from "echarts";

    export default {
        setup(){
            let levelChart;

            const school = ref({});
            const route = useRoute();
            let schoolId = ref(route.query.schoolId)


            const store = useStore();

            const getSchoolDetail = () => {

                request
                    .get("/api/school/getBySchoolId?schoolId=" + schoolId.value )
                    .then((res) => {
                        if (!! res){

                            if (res.code == 20000) {
                                school.value = res.data.schoolDetail.school;
                                /*majorScoreList.value = res.data.schoolDetail.majorScoreList;
                                beforeThreeYearsMinScores.value = res.data.schoolDetail.beforeThreeYearsMinScores;*/


                            } else {
                                ElMessage.error({
                                    message: "获取失败:" + res.message,
                                });
                            }
                        }
                    }).finally(()=>{
                   //initLevelChart();
                })

            };

            onMounted(() => {

                getSchoolDetail();

            });



            const initLevelChart = () => {
                levelChart = echarts.init(document.getElementById('level-chart'));
                levelChart.setOption({
                    //标题样式
                    title: {
                        text: "学术水平",
                        textStyle: {
                            color: 'black',
                        },
                        top: '4%',
                        left: 'center'
                    },
                    grid: {
                        top: '18%',
                        left: '18%',
                        right: '2%',
                        bottom: '3%'
                    },
                    color: ['#f5940c'],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    xAxis: {
                        type: 'value',
                        axisLabel: {
                            show: true,
                            color: 'white',
                            fontSize: 12,
                            formatter: function (value) {
                                if (value >= 10000) {
                                    value = value / 10000 + 'w';
                                }
                                return value;
                            }
                        },
                        axisLine: {
                            show: true
                        },
                    },
                    yAxis: {
                        type: 'category',
                        inverse: true,
                        data: ['院士数', "国家重\n点学科", "硕士点", "博士点"],

                    },
                    series: [{
                        label: {
                            show: true,
                            formatter: '{c}',
                            fontSize: 16,
                            color: 'white',
                            rich: {
                                name: {}
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [school.value.academicians, school.value.special, school.value.dr, school.value.master],
                        type: 'bar',
                        barMaxWidth: "60%",
                    }]
                });
            }

            return{
                getSchoolDetail,
                school,
                schoolId
            }
        }
    }
</script>

<style scoped>

</style>