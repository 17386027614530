<template>


        <div id="tag-card" class="common-card">
            <div class="logo-wrap">
               <el-image class="logo" :src="school.schoolBadge" />
            </div>
            <div class="right-content" style="width: 100%;">
                 <div class="name-pos">
                     {{ school.schoolName }}
                 </div>

                <div style="width: 100%">

                    <div style="float: left">
                        <p>院校代码：{{school.schoolId}}</p>
                        <p>官方网址：

                        <el-link :underline="false" :href="school.site" target="_blank" style="color: black">{{school.site}}</el-link>

                        </p>
                        <p>官方电话：{{school.phone}}</p>
                    </div>
                    <div style="float: left;margin-left: 10%">
                        <p>院校类别：{{school.schoolType}}</p>
                        <p>院校所属：{{school.schoolOwn}}</p>
                        <p>院校地址：{{school.cityName}}</p>
                    </div>

                </div>


            </div>
        </div>


    <el-menu class="menu"
             :default-active="onRoutes"
             mode="horizontal"
             @select="handleSelect">

        <el-menu-item v-for="item in menu" :key="item.path" :index="item.path" >
            <template v-slot:title>
                <span style="font-size: 15px;">{{ item.lable }}</span>
            </template>
        </el-menu-item>
        <el-menu-item >

            <el-link :underline="false" :href="school.enrollmentPlan" target="_blank" style="color: black">
                <span  style="font-size: 15px;">招生简章</span>
            </el-link>
        </el-menu-item>

    </el-menu>
</template>

<script>
    import { useRoute } from "vue-router";
    import { useStore } from "vuex";
    import {ref,computed, onMounted} from "vue";
    import router from '@/router'
    import request from "@/utils/request";
    import {ElMessage} from "element-plus";

    export default {
        setup(){
            const route = useRoute();
            const school = ref({});
            let schoolId = ref(route.query.schoolId)

            const menu = [

                {
                    path: '/schoolHome/schoolInfo?schoolId=' + schoolId.value,
                    name: 'schoolInfo',
                    lable: "院校介绍",
                    url: "/schoolHome/schoolInfo?schoolId=" + schoolId.value
                },
                {
                    path: '/schoolHome/schoolThisYear?schoolId=' + schoolId.value,
                    name: 'schoolThisYear',
                    lable: "招生计划",
                    url: "/schoolHome/schoolThisYear?schoolId=" + schoolId.value
                },{
                    path: '/schoolHome/schoolMajor?schoolId=' + schoolId.value,
                    name: 'schoolMajor',
                    lable: "录取数据",
                    url: "/schoolHome/schoolMajor?schoolId=" + schoolId.value
                },
            ];



            const  onRoutes = computed(()=>{
                return route.path
            })

            const store = useStore();

            const handleSelect = (index) =>{
                router.push(index);
            };

            const getSchoolDetail = () => {

               request
                    .get("/api/school/getBySchoolId?schoolId=" + schoolId.value )
                    .then((res) => {
                        if (!! res){

                            if (res.code == 20000) {
                                school.value = res.data.schoolDetail.school;
                                /*majorScoreList.value = res.data.schoolDetail.majorScoreList;
                                beforeThreeYearsMinScores.value = res.data.schoolDetail.beforeThreeYearsMinScores;*/


                            } else {
                                ElMessage.error({
                                    message: "获取失败:" + res.message,
                                });
                            }
                        }
                    })

            };

            onMounted(() => {

                getSchoolDetail();

            });

            return{
                onRoutes,
                menu,
                handleSelect,
                getSchoolDetail,
                school,
                schoolId
            }
        }
    }

</script>

<style scoped>
    .tag-line {
        display: flex;
        margin-top: 10px;
    }

    .tag {
        border-radius: 40vh;
        color: #f5940c;
        padding: 10px 15px;
        margin: 5px;
        text-align: center;
        text-decoration: none;
        border: 2px solid #f5940c;
        display: inline-block;
        cursor: pointer;
        font-weight: bold;
        background-color: #FFFFFF;
    }

    .name-pos {
        font-size: xx-large;
        color: #FFFFFF;
        margin-bottom: 10px;
    }

    .right-content {
        padding: 10px
    }

    .logo {
        width: 100%;
        height: 100%;
    }

    .logo-wrap {
        float: left;
        background: #FFFFFF;
        padding: 20px;
        margin: 20px;
        border-radius: 40px;
    }

    #tag-card {
        width: 100%;
        height: 200px;
        background-image: linear-gradient(135deg, #f5940c 10%, #fae52a 100%);
        display: flex;
        flex-direction: row;
    }

    .common-card {
        border-radius: 10px;
        box-shadow: 0 0 13px #e6e6e6;
        margin: 10px;
        padding: 10px;
    }


</style>