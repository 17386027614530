import axios from 'axios';
import router from '@/router'
import {ElMessage} from 'element-plus'
import store from "../store/index";
import {computed,onMounted, ref} from "vue";

const Authorization = computed(() => store.state.Authorization);

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  //baseURL: "http://test.xunfengshuju.com:8090",
  timeout: 1000*60*20,
});

//request 拦截器
//自请求发送前进行处理
service.interceptors.request.use(
  config => {

      config.headers.Authorization = Authorization.value? Authorization.value : '';
    return config;
  },
  error => {

    return Promise.reject();
  }
);

//response 拦截器
//在接口响应后统一处理
service.interceptors.response.use(
  response => {
    let res = response.data;
    if (response.status === 200) {
      //如果返回的是文件
      if (response.config.responseType === 'blob') {
        return res;
      }
      //兼容返回的字符串数据
      if (typeof res === 'string') {
        res = res ? JSON.parse(res) : res;
      }
      return res;
    } else {


      Promise.reject();
    }
  },
  error => {


      switch (error.response.status) {
          case 400: error.message = '请求错误(400)'; ElMessage.error(error.message);  break;
          case 401: error.message = '未授权，请重新登录(401)';  alert(error.message); router.push('/login');break;
          case 403: error.message = '拒绝访问(403)'; ElMessage.error(error.message); break;
          case 404: error.message = '请求出错(404)'; ElMessage.error(error.message); break;
          case 408: error.message = '请求超时(408)'; ElMessage.error(error.message);  break;
          case 500: error.message = '服务器错误(500)'; ElMessage.error(error.message); break;
          case 501: error.message = '服务未实现(501)'; ElMessage.error(error.message); break;
          case 502: error.message = '网络错误(502)';ElMessage.error(error.message);  break;
          case 503: error.message = '服务不可用(503)'; ElMessage.error(error.message); break;
          case 504: error.message = '网络超时(504)'; ElMessage.error(error.message); break;
          case 505: error.message = 'HTTP版本不受支持(505)'; ElMessage.error(error.message); break;
          default: error.message = '连接出错(' + error.response.status + ')!';
      }



    return Promise.reject();
  }
);

export default service;
