import { createStore } from 'vuex'
import Vue from 'vue'
import Vuex from 'vuex'


export default createStore({
  state: {
    showRegisterDialog: false,
    showSelectDialog: false,
    showMajorSelect: false,
    showMyMessage: false,
    schoolId: '',
    isLogin: '',
    Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
    user: localStorage.getItem("user") ? localStorage.getItem("user") : '',
    preference: localStorage.getItem("preference")? localStorage.getItem("preference") : '',
    devScore: 0
  },
  mutations: {
    showRegisterDialog(state) {
      state.showRegisterDialog = true;
    },
    closeRegisterDialog(state) {
      state.showRegisterDialog = false;
    },
    showSelectDialog(state, preference) {
      state.showSelectDialog = true;
      state.preference = JSON.stringify(preference)
      localStorage.setItem("preference", JSON.stringify(preference))
    },
    closeSelectDialog(state) {
      state.showSelectDialog = false;
      localStorage.removeItem("preference")
    },
    showMajorSelect(state, schoolId) {
      state.schoolId = schoolId;
      state.showMajorSelect = true;
    },
    closeMajorSelect(state) {
      state.showMajorSelect = false;
    },
    changeLogin(state, Authorization) {
      state.Authorization = Authorization;
      localStorage.setItem('Authorization', Authorization);
    },
    removeAuthorization(state) {
      localStorage.removeItem('Authorization')
    },
    saveUser(state, user){
      state.user = user
      localStorage.setItem("user", user)
    },
    showMyMessage(state){
      state.showMyMessage = true;
    },
    closeMyMessage(state){
      state.showMyMessage = false;
    }
    ,
    savePreference(state, preference){
      state.preference = JSON.stringify(preference)
      localStorage.setItem("preference", JSON.stringify(preference))
    },
    outLogin(state){
      localStorage.removeItem('Authorization');
      localStorage.removeItem("user")
      localStorage.removeItem("preference")
    },
    updateDevScore(state, devScore){
      state.devScore = devScore
    }

  },
  getters: {

  },
  actions: {},
  modules: {}
})