<template>
    <el-card>
        <el-input v-model="majorName" @keyup.enter="getSchoolDetail"  placeholder="请输入专业名称"
                  style="margin-left: 20px;width: 200px"/>


        <el-button @click="getSchoolDetail">

            <el-icon>
                <Search />
            </el-icon>搜索
        </el-button>
        <el-table :data="majorScoreList"  style="width: 100%"
                  :header-cell-style="{ background: '#2775b6', color: 'white' }"
                  max-height="500px">
            <el-table-column fixed prop="majorId" label="专业代码" />
            <el-table-column fixed prop="majorName" label="专业名称" />
            <el-table-column prop="schoolType" label="类别"/>

             <el-table-column prop="studentNumber" label="计划招生"  />
             <el-table-column prop="studentYear" label="学制" />
             <el-table-column prop="studentTuition" label="学费" />

        </el-table>
    </el-card>
</template>

<script>
    import { useRoute } from "vue-router";
    import { useStore } from "vuex";
    import {ref,computed, onMounted} from "vue";
    import request from "@/utils/request";
    import {ElMessage} from "element-plus";

    export default {
        setup(){
            const school = ref({});
            const route = useRoute();
            let schoolId = ref(route.query.schoolId)
            const majorScoreList = ref([]);
            const majorName = ref('')
            const store = useStore();

            const getSchoolDetail = () => {

                request
                    .get("/api/school/getPlan",{

                        params:{
                            schoolId: schoolId.value,
                            majorName: majorName.value
                        }

                    })
                    .then((res) => {


                            if (res.code == 20000) {
                                school.value = res.data.school;
                                majorScoreList.value = res.data.majorScoreList;



                            } else {
                                ElMessage.error({
                                    message: "获取失败:" + res.message,
                                });
                            }

                    })

            };

            onMounted(() => {

                getSchoolDetail();

            });

            return{
                majorScoreList,
                getSchoolDetail,
                school,
                schoolId,
                majorName
            }
        }
    }
</script>

<style scoped>

</style>