import {createRouter, createWebHistory} from 'vue-router'
import store from "../store/index";
import SchoolMain from "@/views/schoolDetail/SchoolMain"
import SchoolInfo from "@/views/schoolDetail/SchoolInfo";
import SchoolThisYear from "@/views/schoolDetail/SchoolThisYear";
import SchoolMajor from "@/views/schoolDetail/SchoolThreeMajorMessage";
import {computed, ref} from "vue";


const routes = [
    {
        path: '/',
        redirect: '/systemHome'
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue'),
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
        children: [{
            path: '',
            name: 'home',
            redirect: '/home/homeMain'
        },
            {
                path: 'homeMain',
                name: 'homeMain',
                component: () => import('../views/homeMain')
            },
            {
                path: 'school',
                name: 'school',
                component: () => import('../views/SchoolView.vue'),
            },
            {
                path: 'special',
                name: 'special',
                component: () => import('../views/SpecialView.vue'),
            },
            {
                path: 'recommend',
                name: 'recommend',
                component: () => import('../views/RecommendView.vue'),
            },{
                path: "myMessage",
                name: "userMessage",
                component: () => import('../views/MyMessage')
            }
        ]
    },
    {
        path: '/schoolHome',
        name: 'schoolHome',
        component: SchoolMain,
        children: [
            {
                path: '',
                name: 'schoolHome',
                redirect: '/schoolHome/schoolInfo'
            },
            {
                path: 'schoolInfo',
                name: 'schoolInfo',
                component: SchoolInfo
            },
            {
                path: 'schoolThisYear',
                name: 'schoolThisYear',
                component: SchoolThisYear
            }, {
                path: 'schoolMajor',
                name: 'schoolMajor',
                component: SchoolMajor
            }
        ]
    },
    {
        path: '/schoolDetail',
        name: 'schoolDetail',
        component: () => import('../views/SchoolDetailView')
    },
    {
        path: "/admin",
        component: () => import('../views/admin/AdminView'),
        children: [
            {
                path: '',
                name: 'adminHome',
                redirect: '/admin/message'
            },
            {
                path: "upload",
                name: "upload",
                component: () => import('../views/admin/upload_zhuan_ke_zhuan_ye_ben_ke_zhuan_ye'),
            },
            {
                path: "uploadSchool",
                name: "uploadSchool",
                component: () => import('../views/admin/UploadSchool'),
            },
            {
                path: "uploadMajor",
                name: "uploadMajor",
                component: () => import('../views/admin/UploadMajor'),
            }
            ,{
                path: "myUser",
                name: "myUser",
                component: () => import('../views/admin/MyUser'),
            },{
                path: "myFirstUser",
                name: "myFirstUser",
                component: () => import('../views/admin/MyFirstUser'),
            },{
                path: "message",
                name: "message",
                component: () => import('../views/admin/Message')
            },{
                path: "myMessage",
                name: "myMessage",
                component: () => import('../views/MyMessage')
            },{
                path: "allOrders",
                name: "allOrders",
                component: () => import('../views/admin/AllOrders')
            },{
                path: "adminNotice",
                name: "adminNotice",
                component: () => import('../views/admin/Notice')
            },{
                path: "adminServe",
                name: "adminServe",
                component: () => import('../views/admin/Serve')
            },{
                path: "afterSale",
                name: "afterSale",
                component: () => import('../views/admin/AfterSale')
            },{
                path: "examPaper",
                name: "examPaper",
                component: () => import('../views/admin/examPaper')
            },{
                path: "statistics",
                name: "statistics",
                component: () => import('../views/admin/Statistics')
            },{
                path: "tips",
                name: "tips",
                component: () => import('../views/admin/Tips')
            },{
                path: "policy",
                name: "policy",
                component: () => import('../views/admin/Policy')
            },{
                path: "testCenter",
                name: "testCenter",
                component: () => import('../views/admin/TestCenter')
            },{
                path: "bulletin",
                name: "bulletin",
                component: () => import('../views/admin/Bulletin')
            },{
                path: "vipGift",
                name: "vipGift",
                component: () => import('../views/admin/VipGift')
            },{
                path: "wxUser",
                name: "wxUser",
                component: () => import('../views/admin/WxUser')
            },{
                path: "wxOrder",
                name: "wxOrder",
                component: () => import('../views/admin/WxOrder')
            },{
                path: "myDocument",
                name: "myDocument",
                component: () => import('../views/admin/MyDocument')
            }
        ]
    }
    , {
        path: '/first',
        name: 'first',
        component: () => import('../views/firstUser/FirstUserView'),
        children: [
            {
                path: '',
                name: 'firstHome',
                redirect: 'first/myCardOrder'
            },
            {
                path: 'myCardOrder',
                name: 'myCardOrder',
                component: () => import('../views/firstUser/MyCardOrder'),
            }, {
                path: 'mySecondUser',
                name: 'mySecondUser',
                component: () => import('../views/firstUser/MySecondUser')
            }, {
                path: 'mySecondUserOrder',
                name: 'mySecondUserOrder',
                component: () => import('../views/firstUser/MySecondUserOrder')
            },{
                path: "firstUserMessage",
                name: "firstUserMessage",
                component: () => import('../views/firstUser/FirstUserMessage')
            }
        ]
    },
    {
        path: '/second',
        name: 'second',
        component: () => import('../views/secondUser/SecondUserView'),
        children: [
            {
                path: '',
                name: 'secondHome',
                redirect: 'second/myOrder'
            },
            {
                path: 'myOrder',
                name: 'myOrder',
                component: () => import('../views/secondUser/MyOrder')
            },{
                path: "myMessage",
                name: "mySecondMessage",
                component: () => import('../views/MyMessage')
            }
        ]
    },{
        path: '/systemHome',
        name: 'systemHome',
        component: ()=> import('../views/SystemHome/Home'),
        children: [
            {
                path: '',
                name: 'systemHome',
                redirect: 'systemHome/main'
            },{
                path: 'main',
                name: 'main',
                component: ()=> import('../views/SystemHome/Main'),
            },{
                path: 'notice',
                name: 'notice',
                component: ()=> import('../views/SystemHome/Notice'),
            },{
                path: 'payCard',
                name: 'payCard',
                component: ()=> import('../views/SystemHome/PayCard'),
            },{
                path: 'forgotPassword',
                name: 'forgotPassword',
                component: ()=> import('../views/SystemHome/ForgotPassword'),
            },{
                path: 'serve',
                name: 'serve',
                component: ()=> import('../views/SystemHome/Serve'),
            },{
                path: 'document',
                name: 'document',
                component: ()=> import('../views/SystemHome/Document'),
            }

        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach((to, from, next) => {

    document.title = '巽风数据';
    // console.log(to.path)
    if (to.path.match('/systemHome')){
        next();
    } else if (to.path.startsWith("/login" )) {

        /*store.commit("removeAuthorization");
        store.commit("outLogin");*/


        next()
    } else {

        let token = localStorage.getItem('Authorization')

        if (token === null || token === '') {
            confirm("您还没有登陆，请先进行登录")
            next('/login');
        } else {

            const me = computed(() => store.state.user);
            const role = ref(JSON.parse(me.value).role)

            switch (role.value) {

                case 3: {
                    if (to.path.match("/home") || to.path.match("/schoolHome")) {
                        next();
                    } else {
                        confirm("您没有权限！401")
                        next('login');

                    }
                    break;
                }
                case 2: {
                    if (to.path.match("/second")) {
                        next();
                    } else {
                        confirm("您没有权限！401")
                        next('login');
                    }
                    break;
                }
                case 1: {
                    if (to.path.match("/first")) {
                        next();
                    } else {
                        confirm("您没有权限！401")
                        next('login');
                    }
                    break;
                }
                case 0: {
                    if (to.path.match("/admin")) {
                        next();
                    }else {
                        confirm("您没有权限！401")
                        next('login');
                    }
                    break;
                }
                default: {

                    confirm('401，您的身份信息有问题，请联系管理员！')
                    next('/login')
                }

            }

        }
    }

})

export default router